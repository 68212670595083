.sms_code_block {
  &.without_padding {
    padding: 0;
  }

  .title {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #1a1a1a;
    margin-top: 1rem;
  }

  .sms_code {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;

    .number_input {
      width: 6rem !important;
      height: 6rem !important;
      border-right: none;
      line-height: 6rem;
      border-radius: 1rem;
      font-size: 2rem;
      background: #f8f8f8;
      border: none;
      text-align: center;
      -moz-appearance: textfield;
      font-family: 'Open Sans Rounded';
      color: #000000;
      flex-shrink: 0;
      flex-basis: auto;
      flex-grow: 0;

      &.selected {
        caret-color: #000000;
        border: 1px solid #777777 !important;
      }

      &:focus {
        border: none;
        color: #000000;
        caret-color: #000000;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &.wrong {
        border: 1px solid #ff3e6c !important;
      }

      &.correct {
        border: 1px solid #54be00;
      }
    }
  }

  .sms_message {
    padding-top: 1.5rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
    text-align: center;
    color: #ff3e6c;
    display: none;

    &.show {
      display: block;
    }
  }
}
