.item_content {
  display: flex;
  width: calc(100% - 4rem);
  margin: 0 auto;
  margin-bottom: 2.5rem;

  &:last-child {
    margin-bottom: 0;
  }
  .img_block {
    width: 8.2rem;
    height: 8.9rem;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .item_info {
    margin-left: 1.9rem;
    .item_name {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000000;
    }
    .item_volume {
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000000;
      margin-bottom: 1rem;
    }

    .additionals {
      .additionals_item {
        display: flex;
        .additionals_plus {
          display: flex;
          margin-right: 1rem;
          svg {
          }
        }
        .list {
          .additionals_txt {
            ont-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 130%;
            color: #000000;
          }
        }
      }
    }
  }
  .price {
    font-style: normal;
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 130%;
    color: #ff7a00;
    margin-left: auto;
    white-space: nowrap;
  }
}
