.coffe_count {
  display: flex;
  align-items: center;
  font-size: 2rem;
  line-height: 130%;
  color: #b0b0b0;
  svg {
    padding-right: 0.5rem;
  }
}

.container_inner {
  width: calc(100% - 4rem);
  margin: 0 auto;
  margin-bottom: 3rem;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-bottom: 0.2rem;
  }
  .address {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;
    color: #000000;
    margin-bottom: 1.2rem;
  }

  .work_time {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    text-align: center;
    color: #b0b0b0;
    margin-bottom: 1.2rem;
  }

  .stat_block {
    display: flex;
    justify-content: center;
    margin-bottom: 1.8rem;
    .stat_txt {
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      color: #f8f9fd;
      background: #b0b0b0;
      border-radius: 50px;
      padding: 0 1rem;
    }
  }

  .rating {
    background: #f8f8f8;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 3rem 3.3rem;
    box-sizing: border-box;
    margin-bottom: 2rem;

    .rating_title {
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      line-height: 120%;
      color: #ff7a00;
      margin-bottom: 1.1rem;
    }
    .rating_subtitle {
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 130%;
      text-align: center;
      color: #000000;
      margin-bottom: 2rem;
    }
    .stars {
      display: flex;
      gap: 1rem;
    }
  }
  .products_container {
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    padding: 2.5rem 0;
    margin-bottom: 2rem;

    &.empty {
      margin-bottom: 0;
      padding: 0;
      border: none;
    }
  }
}

.success_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50vh;
  width: calc(100% - 4rem);
  margin: 0 auto;
  .success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    .cat_img {
      width: 10rem;
      height: 10rem;
      margin-bottom: 3rem;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .success_title {
      font-style: normal;
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 130%;
      text-align: center;
      color: #000000;
    }
    .success_subtitle {
      font-size: 1.6rem;
      line-height: 130%;
      margin-top: 1.5rem;
      text-align: center;
      color: #000;
    }
  }
}

.map {
  height: 15rem;
  margin-bottom: 2rem;
}
