.container {
  width: 100%;
  height: 100%;
}
.map_container {
  width: 100%;
  height: 100%;
}
.cluster {
  // width: 3rem;
  // height: 3rem;
  // border-radius: 50%;
  > div {
    border-radius: 50%;
    background-color: #ff7a00;
    color: #fff !important;
    font-weight: 400;
    font-size: 1.6rem !important;
  }
  img {
    display: none !important;
  }
}
.back_btn {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 2rem;
  left: 2rem;
  z-index: 9999;
  svg {
    path {
      fill: #000;
    }
  }
}
