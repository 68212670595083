.container {
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.content {
  flex: 1;
  padding: 0 2.5rem 2.5rem;
}

.auth_btn {
  margin-top: 2rem;
}

.code_info {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #1a1a1a;
  margin-top: 2rem;
  text-align: center;
}

.comment_title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
}

.comment {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 115%;
  color: #ff7a00;
  text-align: center;
  margin-top: 1.5rem;
}
.comment_input {
  border-radius: 1.5rem !important;
}

.map {
  width: 100%;
  height: 15rem;
  margin: 1.5rem 0 1rem 0;
}

.bottom_bar_container {
  position: unset;
  margin-top: 2rem;
}
.balance_promo_block {
  margin: 2rem 0;
}
.balance_checkbox {
  display: flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
    background: #ff7a00;
    border-radius: 2rem;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    color: #fff;
    padding: 0.3rem 1.5rem;
  }
}
