.container {
  padding: 0 2.5rem 2.5rem;
}
.title {
  font-size: 2.4rem;
  line-height: 3.1rem;
  color: #000;
  font-weight: bold;
  text-align: center;
}

.barista_block {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  .img {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .barista_info {
    margin-left: 2rem;
    .user_name {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
    }
    .user_subtitle {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 130%;
      margin-top: 0.5rem;
    }
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3rem;
  .btn {
    width: calc(50% - 1rem);
    height: 0;
    padding-bottom: calc(50% - 1rem);
    position: relative;
    background: #f6f6f6;
    border-radius: 1rem;
    .btn_title {
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 115%;
      color: #000;
      padding-top: 1rem;
    }
    &.orange {
      background: #ff7a00;
      .btn_title {
        color: #fff;
      }
    }
  }
  .inner {
    position: absolute;
    padding: 2rem;
    left: 0;
    bottom: 0;
  }
}
.loader {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.horizontal_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.2rem;
  background: #f6f6f6;
  border-radius: 1rem;
  margin-top: 2rem;
  .btn_title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 115%;
    color: #000;
    padding-left: 1rem;
  }
}
