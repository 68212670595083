.btn_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btn {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  line-height: 130%;
  border-radius: 3rem;
  font-weight: 700;
  border: none;
  font-family: 'Open Sans Rounded';

  img {
    width: 2.4rem;
  }

  &.with_icon {
    img {
      margin-right: 1rem;
    }
  }

  &.gray {
    background-color: #e7e7e7;
    color: #303030;
  }

  &.red {
    background-color: #ff2b2b;
    color: #ffffff;
  }
  &.green {
    background-color: #24ca49;
    color: #ffffff;
  }
  &.orange {
    background-color: #ff7a00;
    color: #ffffff;
  }
  &.white {
    background: #ffffff;
    box-shadow: 0 0.4rem 1rem rgba(200, 200, 200, 0.42);
    border-radius: 3rem;
    color: #000;
  }
  &.withoutShadow {
    box-shadow: none;
  }

  &.disabled {
    pointer-events: none;
    // opacity: 0.5;
    background-color: #f6f6f6;
  }
}
