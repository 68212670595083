.sheet_modal {
  height: 100%;
  max-height: 98%;
  border-radius: 10px 10px 0px 0px;
  font-size: 1.4rem;

  &.non_active {
    // pointer-events: none;
  }

  &:before {
    display: none !important;
  }

  &.auto_height {
    height: auto;
    padding-bottom: 2rem;
  }

  > div {
    border-radius: 10px 10px 0px 0px;
  }

  .bs_page_content {
    height: calc(100% - 4rem);
  }

  &.auto_height {
    height: auto;
    max-height: 98%;
  }
}

.top_close_button > div {
  width: 9rem;
  height: 0.8rem;
  background: #dedede;
  border-radius: 1rem;
  margin: 2rem auto;
}
