.container {
  padding: 5rem 2.5rem;
}
.outlets {
  margin-top: 2rem;
}
.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  color: #000;
  padding: 0 1.5rem;
}
.subtitle {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 130%;
  color: #000;
  padding: 0 1.5rem;
}
.item {
  background: #f8f8f8;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  .img {
    width: 9rem;
    height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: contain;
      width: 9rem;
    }
    svg {
      width: 7rem;
      height: 7rem;
    }
  }
}
