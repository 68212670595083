.container {
  display: flex;
  border: 0.1rem solid #f0f0f0;
  border-radius: 2rem;
  padding: 2rem;
  margin-top: 1rem;

  .left.gray > div {
    color: #8b8b8b !important;
  }

  .title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
  }
  .addr {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 120%;
    color: #000;
    margin-top: 0.4rem;
  }
  .time {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 130%;
    color: #b0b0b0;
    margin-top: 0.4rem;
  }
  .right {
    flex-shrink: 0;
    padding-left: 1rem;
    margin-left: auto;
  }
  .ready_container {
    background: #19b2ac;
    border-radius: 100rem;
    padding: 0.7rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 800;
    font-size: 1.2rem;
    line-height: 130%;
    color: #fff;
    svg {
      padding-right: 0.5rem;
      width: 1.2rem;
    }
  }
}
