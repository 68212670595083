.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2.5rem;
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;

  &.wait_container {
    justify-content: center;

    .text,
    .subtitle {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000;
      margin-top: 1.5rem;
      text-align: center;
    }

    .btn_container {
      margin-top: 2rem;
      width: 28rem;
    }
  }
}
