.container {
  display: flex;
  background: #f6f6f6;
  border-radius: 1rem;
  padding: 1.5rem;
  margin-top: 1rem;
  .img_block {
    position: relative;
    width: 6rem;
    height: 6rem;
    flex-shrink: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .products_length {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 3rem;
      height: 3rem;
      background-color: #fff;
      border-radius: 50%;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 130%;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .order_info {
    padding-left: 1.5rem;
    flex: 1;
    .title {
      color: #000;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
    }
    .subtitle {
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 120%;
      color: #000;
      margin-top: 0.2rem;
    }
    .o_number {
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 130%;
      margin-top: 1rem;
      color: #b0b0b0;
    }
    .stat_block {
      width: fit-content;
      padding: 0.2rem 1rem;
      margin-top: 1rem;
      border-radius: 5rem;
      background: #19b2ac;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 130%;
      color: #fff;
    }
  }
  .trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.5rem;
    flex-shrink: 0;
    svg {
      width: 1.2rem;
      height: 2rem;
      path {
        fill: #b0b0b0;
      }
    }
  }
}
