.out_container {
  margin-top: 2rem;
}
.title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  margin-bottom: 1rem;
}
.container {
  display: flex;
  align-items: center;
  background: #f8f8f8;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem 1rem;
}
.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.name {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 130%;
  color: #000;
  margin-left: 1rem;
}
.trigger {
  margin-left: auto;
  svg {
    width: 1rem;
    height: 2rem;
    transform: translateY(0.2rem);
    path {
      fill: #000;
      opacity: 1;
    }
  }
}
