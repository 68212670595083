.container {
  height: 100%;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.location_container {
  padding: 0 2.5rem;

  .location_title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    margin-bottom: 1.5rem;
  }
}
