.container {
  background-color: #f6f6f6;
  padding: 2rem 0;
}
.title {
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #000;
  padding: 0 2.5rem;
}

.products_container {
  padding: 0 2.5rem;
  margin-top: 1rem;
  display: flex;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  &::-webkit-scrollbar {
    display: none;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 2rem;
  background: #fff;
  border-radius: 1rem;
  overflow: hidden;
  width: 25rem;
  margin-right: 1rem;
  flex-shrink: 0;
  box-sizing: border-box;
}
.img_container {
  width: 7rem;
  height: 7rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.item_info {
  padding-left: 1.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .item_name {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: #000;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .add_btn {
    display: flex;
    align-items: center;
    padding: 0.2rem 1rem;
    background-color: #ff7a001a;
    border-radius: 3rem;
    margin-top: 0.8rem;
    align-self: flex-start;
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
    .item_price {
      margin-left: 0.4rem;
      color: #ff7a00;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }
}
