.container {
  padding: 0 2.5rem 6.5rem;

  .title {
    font-weight: 700;
    font-size: 2rem;
    line-height: 130%;
    color: #000;
    margin-bottom: 2.5rem;
  }
}
