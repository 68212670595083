.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
  }
  .checkbox {
    width: 2.6rem;
    height: 2.6rem;
    background: #f0f0f0;
    border-radius: 0.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    &.checked {
      background: #ff7a00;
    }
    svg {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
