.content {
  padding: 0 2.5rem 2.5rem;

  .title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
    &.orange {
      color: #ff7a00;
    }
  }
  .actually_block {
    margin-bottom: 1rem;
  }
}
