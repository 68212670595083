.content {
  padding: 0 2.5rem;
  .content_title {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 115%;
    text-align: center;
    padding: 0 1.5rem;
  }
  .title {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 115%;
    text-align: center;
    margin-top: 5rem;
    padding: 0 1.5rem;
  }
}

.phone_input {
  input {
    text-align: center;
    font-size: 2rem;
  }
}
.input {
  input {
    text-align: center;
  }
}
.btn {
  margin-top: 2rem;
}
.pass_reset {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 130%;
  padding: 1rem;
  color: #ff7a00;
  margin-top: 1rem;
  text-align: center;
}

.btn_container {
  position: absolute;
  bottom: 2.5rem;
  width: 100%;
  padding: 2.5rem;
  box-sizing: border-box;
}
.errmsg {
  margin-top: 1.5rem;
}
.pass_pin {
  margin-top: 3rem;
}
