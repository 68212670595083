.container {
  position: relative;
  &.small {
    width: 5rem;
    height: 5rem;
  }

  .circle {
    &.small {
      width: 5rem !important;
      height: 5rem !important;

      svg {
        width: 5rem !important;
        height: 5rem !important;
      }
    }
  }

  .inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
