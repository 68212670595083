.content {
  margin-top: 3rem;
}
.title {
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #000;
  font-weight: bold;
}
.item {
  margin-top: 2rem;
  width: calc(100% / 3 - 1rem);
  padding-bottom: calc(100% / 3 - 1rem);
  box-sizing: border-box;
  position: relative;
  margin-right: 1.5rem;
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.item_inner {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #f6f6f6;
  border-radius: 1.5rem;
  overflow: hidden;
}
.item_txt {
  margin-top: auto;
  color: #000;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  text-align: center;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_container {
  width: 100%;
  height: 6rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.full {
  width: 100%;
  height: 100%;
}
.items_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.icon {
  position: absolute;
  top: -0.5rem;
  left: 0.2rem;
}
