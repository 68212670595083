.page_layout {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
}
.content {
  background: #ffffff;
  flex: 1;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  height: 100%;
}
