.container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem 1rem;
  background: #f6f6f6;
  border-radius: 100rem;
  flex-wrap: wrap;
  min-height: 6.2rem;
}
.vertical {
  flex-direction: column;
  border-radius: 3rem;
  .item {
    width: 100% !important;
  }
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 5.2rem;
  border-radius: 100rem;
  overflow: hidden;
  min-width: 30%;

  &.selected {
    &.orange {
      background-color: #ff7a00;
    }
    &.aqua {
      background-color: #19b2ac;
    }
    .title {
      color: #fff;
      &.disabled {
        color: #b0b0b0;
      }
    }
    .subtitle {
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
.title {
  font-size: 1.6rem;
  line-height: 130%;
  font-weight: 700;
  color: #000;
  text-align: center;
}
.subtitle {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #8b8b8b;
}
