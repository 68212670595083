.container {
  position: sticky;
  bottom: 0;
  margin-top: auto;
  border-top: 1px solid #f8f8f8;
  padding: 2rem 2.5rem;
  background-color: #fff;
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 130%;
      color: #000;
    }
    .right {
      font-weight: 700;
      font-size: 2rem;
      line-height: 130%;
      color: #000;
      &.thin {
        font-weight: 400;
      }
    }
  }
  .btn {
    margin-top: 2rem;
  }
  &.hidden {
    display: none;
  }
}
.loader {
  margin: 0;
  padding: 0;
}
