.container {
  background: #ff7a00;
  border-radius: 1rem;
  padding: 1rem 2rem;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 130%;
  color: #fff;
  display: flex;
  margin: 0 3rem;
  margin-bottom: 2rem;
  .icon {
    padding-left: 1rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
