.title {
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 130%;
  color: #000;
  text-align: center;
}
.container {
  padding: 0 2.5rem;
}
.items {
  padding: 2rem 0;
}

.item {
  display: flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 1rem;
  padding: 1.2rem 1.5rem 1rem;
  margin-top: 1rem;
}

.icon {
  width: 3rem;
  height: 2.4rem;
  svg {
    width: 3rem;
    height: 2.4rem;
  }
}

.name {
  font-size: 1.6rem;
  line-height: 2.1rem;
  margin-left: 1rem;
}
