.content {
  padding: 0 2.5rem 2.5rem;
}

.tips_container {
  display: flex;
  gap: 1rem;
  overflow-y: scroll;
  padding-bottom: 2rem;
  margin-bottom: 1rem;

  .tips_item {
    background: #f8f8f8;
    border-radius: 100px;

    &.active {
      background: #ff7a00;
    }
    .tips_txt {
      font-style: normal;
      font-weight: 700;
      font-size: 1.4rem;
      line-height: 1.9rem;
      color: #000000;
      padding: 1.5rem;
      white-space: nowrap;

      &.txt_active {
        color: #ffffff;
      }
    }
  }

  .variable_item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .variable_image {
      opacity: 0.5;
      width: 5.9rem;
      height: 5.9rem;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
      &.variable_active {
        opacity: 1;
      }
    }
    .variable_txt {
      opacity: 0.5;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #000000;
      white-space: nowrap;
      &.variable_active {
        opacity: 1;
      }
    }
  }
}

.input_container {
  margin-bottom: 2rem;
}
