.container {
  padding: 0 2.5rem 2.5rem;
}
.title {
  font-size: 2.4rem;
  line-height: 3.1rem;
  color: #000;
  font-weight: bold;
  text-align: center;
}
.flex_container {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  flex-wrap: wrap;
}
.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-right: 1.5rem;
  width: calc(100% / 3 - 1rem);
  &:nth-child(3n) {
    margin-right: 0;
  }
  .img_container {
    border-radius: 100rem;
    width: 100%;
    padding-bottom: 100%;
    background-color: #f6f6f6;
    position: relative;
    height: 0;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
    }
  }
  .name {
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    color: #000;
    font-weight: 500;
    height: 3.2rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .price {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #ff7a00;
    font-weight: 500;
  }
}
