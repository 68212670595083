.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.5rem 2.5rem;
}
.loader {
  padding: 0;
  margin: 0 auto;
}
.btn {
  width: calc(50% - 0.5rem);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background: #f6f6f6;
  border-radius: 1rem;
  min-height: 6.64rem;
  svg {
    width: 2rem;
    height: auto;
    flex-shrink: 0;
  }
  .btn_title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 115%;
    color: #000;
    padding-left: 1rem;
  }
}
