.container {
  background: #f8f8f8;
  border-radius: 1.5rem;
  padding: 1rem 1.5rem;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  .title {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
  }
  .subtitle {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 130%;
    color: #000;
  }
  .img {
    width: 6rem;
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      object-fit: contain;
      width: 6rem;
    }
    svg {
      width: 5rem;
      height: 5rem;
    }
  }
  .center {
    margin-left: 1.5rem;
  }
}
